
import React from 'react';
import zendeyaGet from '../image/zendeyaGet.png';
import daveGet from '../image/daveGet.png';
import esherGet from '../image/esherGet.png';
import Cassie from '../image/Cass.png';
import {Row, Col, Card } from 'react-bootstrap';

function Homepage() {
  return (
            <Row className="Reklama_rows__TqASn">
                <section className="mb-2">
                  <Card className="flex-row bg-dark text-white">
                    <Card.Body>
                      <p>
                        Шефер — трансгендерная женщина. В интервью она сказала: «Интернет спас меня — я могла зайти на YouTube и посмотреть, как люди совершали „переход“, увидеть себя в них». Переход произошёл после того, как в девятом классе ей поставили диагноз «дисфория». Шефер заявила: «Я хочу, чтобы люди знали, что я не цис-девочка, потому что это не то, чем я являюсь или чувствую себя такой. Я горжусь тем, что я трансгендер». В 2019 году Шефер сказала, что она «ближе к тому, что можно назвать лесбиянкой». В декабре 2021 года она заявила в Твиттере, что её сексуальность — «би или пан или что-то в этом роде». С февраля 2022 года по июль 2023 года встречалась с коллегой по «Эйфории» Домиником Файком.
                      </p>
                    </Card.Body>
                    <div className="text-center" id="m">
                      <h5>Хантер Шефер</h5>
                      <img
                        src={esherGet}
                        alt="Хантер Шефер"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Card>
                </section>
                <section className="mb-2">
                  <Card className="flex-row bg-dark text-white">
                    <Card.Body>
                      <p>
                      Американская актриса, певица, модель, продюсер. Родилась 1 сентября 1996 года в Окленде (штат Калифорния, США). Имя актрисы происходит от слова «благодарить» на одном из африканских языков. В детстве играла в Калифорнийском театре имени Шекспира, где работала её мать, училась в консерватории и брала уроки танцев. Начала свою карьеру как модель, снимаясь для рекламы брендов одежды. В 2009 году прошла прослушивание и получила роль Рокки Блю в сериале канала «Disney» под названием «Танцевальная лихорадка!» (2010-2013). В 2011 году вышел её первый сингл «Swag It Out». В 2013 году актриса выпустила свой первый музыкальный альбом «Zendaya» и книгу «Between U and Me». Дебютом на большом экране стала роль Мишель «Эм-Джей» Джонс в кинокомиксе «Человек-паук: Возвращение домой» (2017).
                      </p>
                    </Card.Body>
                    <div className="text-center" id="m">
                      <h5>Зендея Мари Штёрмер</h5>
                      <img
                        src={zendeyaGet}
                        alt="Зендея Штёрмер"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Card>
                </section>
                <section className="mb-2">
                  <Card className="flex-row bg-dark text-white">
                    <Card.Body>
                      <p>
                      Австралийский актер, продюсер. Родился 26 июня 1997 года в Брисбене (штат Квинсленд, Австралия). С детства играл в школьных спектаклях и посещал драматический кружок. Учился в колледже Святого Кевина в Тоораке (Мельбурн). Окончил колледж Святого Иосифа, где профессионально играл в американский футбол. С 2015 года снимался в короткометражных фильмах. Известность актеру принесла роль Ноя Флинна в мелодраме «Будка поцелуев» (2018). Исполнил роль Нэйта Джейкобса в телесериале «Эйфория» (2019).
                      </p>
                    </Card.Body>
                    <div className="text-center" id="m">
                      <h5>Дже́йкоб Натаниэ́ль Эло́рди</h5>
                      <img
                        src={daveGet}
                        alt="Дже́йкоб Натаниэ́ль Эло́рди"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Card>
                </section>
                <section className="mb-2">
                  <Card className="flex-row bg-dark text-white">
                    <Card.Body>
                      <p>
                      Си́дни Бе́рнис Суи́ни — американская актриса.Дата и место рождения: 12 сентября 19970г. Спокан, Вашингтон, США. Впервые получила известность в 2018 году благодаря сериалам «Сплошной отстой!», «Острые предметы» и «Рассказ служанки». Через год она снялась в фильме Квентина Тарантино «Однажды в Голливуде»
                      </p>
                    </Card.Body>
                    <div className="text-center" id="m">
                      <h5>Си́дни Бе́рнис Суи́ни</h5>
                      <img
                        src={Cassie}
                        alt="Дже́йкоб Натаниэ́ль Эло́рди"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Card>
                </section>
            </Row>

  );
}

export {Homepage}
