import React from 'react';
import { useParams } from 'react-router-dom';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

function Season2Page() {
    const{id}=useParams()
  return (
    <div>
      <Plyr
        source={{
          type: 'video',
          sources: [
            {
              src: `https://www.eiforiya.site:3001/serias2/${id}`,
              type: 'video/mp4',
            },
          ],
        }}
      />
    </div>
  )
}

export default Season2Page
