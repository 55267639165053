import { Link } from 'react-router-dom'

const Notfoundpage = () => {
    return (
        <div>
            This Not found page <Link to="/">home</Link>
        </div>
    )
}

export {Notfoundpage};
