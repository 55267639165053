import React from 'react';

const images = {
  'First slide': require('./image/Scr_3.png'),
  'Second slide': require('./image/Scr_6.png'),
  'Third slide': require('./image/Scr_8.png')
};

function Karuselimage({ text }) {
  const imageSrc = images[text];
  
  return (
    <img
      className="d-block w-100"
      src={imageSrc}
      alt={text}
    />
  );
}

export default Karuselimage;
