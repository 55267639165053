import React from 'react';
import { useStars } from './StarContext';
import {Button,Col,Card,Row} from 'react-bootstrap';

const Seasn2 = () =>{
  const { sez3 } = useStars();
// Проверка на случай, если данных ещё нет
  if (!sez3 || !sez3.season2) {
    return <p>Loading...Данные загружаются</p>;
}

return (  
<>
  <Row>
      {sez3 && sez3.season2 &&sez3.season2.map((star, index) => (
          <Col xs={12} sm={12} md={4} key={index} className="mb-4">
              <Card className="bg-dark text-white">
                  <Card.Img variant="top" src={`https://www.eiforiya.site:3001/image/${star.image}`} alt={`Seria ${star.episode}`} />
                  <Card.Body>
                      <Card.Title>Краткое описание</Card.Title>
                      <Card.Text>
                        {`${star.description}`}  
                      </Card.Text>
                      <Button variant="primary" href={`/season_2/${star.episode}`}>Серия: {`${star.episode}`}</Button>
                  </Card.Body>
              </Card>
          </Col>
      ))}
  </Row>
</>
  )
}

export  {Seasn2}
