import React from 'react';
/*import styles from './NewModule.module.css';*/
/*import s from './Reklama.module.css';*/
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function Reklama() {
   const fl= [
      {
        "episode": 1,
        "title":"Seria_1",
        "image": "scr_1.png",
        "description": "Первый эпизод сериала состоялась 16 июня 2019.Семнадцатилетняя Ру возвращается домой из реабилитационного центра и встречает Джулс , которая недавно приехала в город. Нейт проникает в голову Маккея, когда тот проявляет интерес к Кэсси .Нейт и Мэдди пытаются вызвать друг у друга ревность. А Кэт оказывается под давлением."
      },
      {
        "episode": 2,
        "title": "Seria_2",
        "image": "scr_2.png",
        "description": "Премьера состоялась 23 июня 2019г.В первый день учебы Ру взволнована своей новой подругой Джулс , но изо всех сил пытается оставить прошлое позади и попадает в неприятности в Феско."
      },
      {
        "episode": 3,
        "title": "Seria_3",
        "image": "scr_3.png",
        "description": "Был выпущен 30 июня 2019г. Кэт решает попробовать себя в веб-каме и проводит свой первый сеанс. Джулс начинает влюбляться в парня в сети и просит Ру помочь ей сделать соблазнительные фотографии. Мэдди находит что-то странное в телефоне Нейта."
      },      {
        "episode": 4,
        "title": "Seria_4",
        "image": "scr_4.png",
        "description": "Премьера состоялась 7 июля 2019г.Ру пытается очиститься для Джулс и сопровождает Джию на карнавале . Джулс сталкивается с Кэлом и его семьей — и, наконец, встречает Тайлера . Кэсси и Мэдди , раздраженные своими парнями , решают заняться... Кэт тусуется с Итаном, пока не понимает ситуацию неправильно."
      },      {
        "episode": 5,
        "title": "Seria_5",
        "image": "scr_5.png",
        "description": "Премьера состоялась 14 июля 2019.После карнавала Мэдди и Нейт вынуждены иметь дело с полицейским расследованием.Джулс начинает чувствовать свою ответственность за Ру.Кэт игнорирует смущенного Итана и принимает свой новый образ жизни. Кэсси пытается дистанцироваться от Дэниела и сосредоточиться на своих отношениях с Маккеем . Кэл беспокоится о том, как его действия повлияли на его сыновей ."
      },      {
        "episode": 6,
        "title": "Seria_6",
        "image": "scr_6.png",
        "description": "На Хэллоуин Джулс начинает вести себя подозрительно, а Ру беспокоится о своей зависимости от нее, чтобы оставаться трезвой. Маккей задается вопросом о своем будущем в футболе. После странной ночи с Маккеем, Кэсси проводит некоторое время с Дэниелом . Бизнес Кэт процветает, хотя она продолжает отталкивать Итана . Нейт придумывает план, как вернуть свою жизнь в нужное русло."
      },      {
        "episode": 7,
        "title": "Seria_7",
        "image": "scr_7.png",
        "description": "Выпущен 28 июля 2019.Ру впадает в депрессию и смотрит 22 серии подряд британского реалити-шоу. Джулс отправляется навестить старого друга . Кэсси ищет совета после Хэллоуинской ночи."
      },      {
        "episode": 8,
        "title": "Seria_8",
        "image": "scr_8.png",
        "description": "Выпущен 4 августа 2019.В финале сезона — зимний бал в Ист-Хайленде . Ру пытается восстановить отношения с Джулс и противостоит Нейту . Кэсси и Мэдди пересматривают свои отношения ."
      }
    ]  
  
    return (
    <>
      {fl.map((item, index) => (
        <Card className="bg-dark text-white"  key={index} >
            <Card.Img variant="top" src={`https:///www.eiforiya.site:3001/image/${item.image}`} />
            <Card.Body>
                <Card.Title>Серия {item.episode}</Card.Title>
                <Card.Text>
                {item.description}
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
            </Card.Body>
        </Card>
      ))}
    </>
    );
  }

export default Reklama
