import React,{createContext, useState,useContext, useEffect} from "react";
import axios from "axios";
const StarContext =createContext();
// проверка исправления
export const StarProvider=({children})=>{//оборачивает App in index.js
const [sez1, setSer] = useState([]);
const [sez2, setSer2] = useState([]);
const [sez3, setSez3] = useState([]);
useEffect(()=>{
    const fetchData = async()=>{
        try {
            // const response = await axios.get("http://localhost:3001/apl/sez3");//get 
             const response = await axios.get("https://www.eiforiya.site:3001/apl/sez3");//get
            console.log(response.data.season1);
            setSez3(response.data);
        } catch (error) {
            console.error("error in  fetchData in StarContext sez3 fetch", error);
        }
        try{
            const response3 = await axios.get("https://www.eiforiya.site:3001/api/sezon1");//get array [1,2...8]
            console.log(response3.data);
            setSer(response3.data);
        }
        catch(Error){console.error("ошибка в fetchData in StarContext-response3");
        }
        try{
            const response3 = await axios.get("https://www.eiforiya.site:3001/api/sezon2");//get array [1,2...8]
            console.log(response3.data);
            setSer2(response3.data);
        }
        catch(Error){console.error("ошибка в fetchData in StarContext-response3");
        }
    };
fetchData();

},[]);
return(
    <StarContext.Provider value={{sez1,sez2,sez3}}>
        {children}
    </StarContext.Provider>
);

}

export const useStars=()=>{//используется в sidebar.jsx
    const context= useContext(StarContext);
    if(!context){
        throw new Error("ошибка  useContext in StarContext.js");
    }
    return(context);
}
